.nav-toggler {
    $icon-size: 50px;
    display: flex;
    align-items: center;
    color: $dark;

    @include mappy-bp(lg) {
        display: none;
    }

    .toggle {
        appearance: none;
        background: $primary;
        border: 0;
        height: $icon-size;
        width: $icon-size;
        cursor:pointer;
        transition: .3s;
        margin:0;
        padding:0;

        svg {
            height: auto;
            width: 100%;
            display: block;
        }
    }
}
