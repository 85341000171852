.page-teaser {
    padding: 0;
    .entries {
        display: grid;
        gap: $padding-xl;

        @include mappy-bp(md) {
            gap:0;
            grid-template-columns: 1fr 1fr 1fr;
            padding-top: rem-calc(250);
        }
    }

    .page {
        --icon-bg: #{$primary};
        @include nolink;
        position: relative;
        text-align: center;

        &:nth-child(2n) {
            --icon-bg: #{$secondary};
        }
    }

    img {
        aspect-ratio: 1;
        display: block;
        object-fit: cover;
    }

    svg {
        display: none;

        @include mappy-bp(md) {
            color: var(--icon-bg);
            display: block;
            max-width: rem-calc(400);
        }
    }

    .btn {
        @include btn;

        bottom: 0;
        left: 50%;
        position: absolute;
        transform: translate(-50%, 50%);
        width: max-content;
    }

    .title-container {
        margin-bottom: $padding;
        position: relative;
        display: block;

        @include mappy-bp(md) {
            margin:0;
            left: $padding;
            padding: 0 $padding-lg 0 $padding;
            position: absolute;
            right: $padding-lg;
            transform: translateY(-20%);
            z-index: 1;
        }
    }

    .title {
        font-size: rem-calc(25);
        font-weight: $bold-font-weight;
        letter-spacing: rem-calc(2);
        line-height: rem-calc(30);
        position: relative;
        text-align: center;
        text-transform: uppercase;
        z-index: 10;

        @include mappy-bp(md) {
            bottom: 25%;
            display: block;
            height: min-content;
            left: 15%;
            position: absolute;
            text-align: left;
            text-orientation: mixed;
            transform: rotate(180deg);
            white-space: break-spaces;
            writing-mode: vertical-rl;
        }
    }

    .text-container {
        background: $primary;
        padding: $padding-lg $padding;

        @include mappy-bp(md) {
            padding-top: $padding-xl;
        }
    }

    .text {
        background: $primary;
        color: $light;
        font-family: $highlight-font-family;
        font-size: rem-calc(30);
        margin: 0 auto;
        max-width: $text-max-width;
    }
}
