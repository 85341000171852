.homepage-hero {
    position: relative;
    background: #f1f1f1;

    img {
        max-height: rem-calc(700);
        object-fit: cover;
        opacity:0;
    }

    .button {
        left: 50%;
        position: absolute;
        text-align: center;
        top: $padding;
        white-space: nowrap;
        transform: translate(-50%, 0);

        @include mappy-bp(md) {
        transform: translate(-50%, -50%);
            top: 50%;
        }

        a {
            @include btn;
        }
    }

    .text {
        background: $primary;
        color: $light;
        font-family: $highlight-font-family;
        font-size: rem-calc(30);
        font-style: italic;
        margin: 0 auto;
        max-width: rem-calc(600);
        padding: $padding $padding-xl;
        text-align: center;

        @include mappy-bp(md) {
            transform: translate(0, -50%);
        }


        strong {
            font-style: normal;
            font-weight: $bold-font-weight;
        }

        em {
            font-weight: $body-font-weight;
        }
    }
}
