.page-footer {
    display: flex;
    flex-direction: column;
    position: relative;

    @include mappy-bp(md) {
        align-items: flex-end;
        flex-direction: row;
    }

    .left {
        align-items: flex-end;
        background: $light;
        box-shadow: 3px 0 20px rgba(0, 0, 0, .15);
        display: grid;
        gap: $padding-lg;
        grid-template-areas: 'social' 'contact' 'name';
        justify-content: center;
        padding: $padding-lg $padding;
        text-align: center;

        @include mappy-bp(md) {
            grid-template-areas: 'contact social' 'name name';
            grid-template-columns: max-content 1fr;
            padding: $padding-lg $padding-lg $padding;
            width: max-content;
        }
    }

    .right {
        @extend %block-max-width;
        background: $primary;
        --link-color: #{$light};
        flex: 1;
        --link-hover-color: #{$secondary};
        position: relative;
        text-align: center;

        @include mappy-bp(md) {
            padding-left: 0;
            text-align: right;
        }
    }

    .contact {
        display: flex;
        flex-direction: column-reverse;;
        grid-area: contact;

        @include mappy-bp(md) {
            display: block;
            text-align: left;
            text-orientation: mixed;
            transform: rotate(180deg);
            writing-mode: vertical-lr;
        }

        a {
            display: block;
            font-family: $highlight-font-family;
            font-size: rem-calc(25);
            --link-hover-color: #{$dark};
            line-height: rem-calc(40);

            @include mappy-bp(md) {
                line-height: rem-calc(25);
                &:last-child {
                    margin-left: $padding-sm;
                }
            }
        }
    }

    .content {
        position: relative;
    }

    .social-media {
        align-items: center;
        display: flex;
        flex-direction: column;
        gap: $padding;
        grid-area: social;
        justify-content: center;

        @include mappy-bp(md) {
            justify-content: flex-start;
        }

        svg {
            display: block;
            height: 60px;
            width: 60px;
        }
    }

    .sitename {
        font-size: rem-calc(50);
        font-weight: $bold-font-weight;
        grid-area: name;
        line-height: rem-calc(50);
        text-align: center;
        text-transform: uppercase;
        width: 100%;

        @include mappy-bp(md) {
            width: max-content;
        }
    }

    .circle {
        bottom: 0;
        display: none;
        height: rem-calc(350);
        left: 0;
        overflow: hidden;
        position: absolute;
        width: 70%;

        @include mappy-bp(lg) {
            display: block;
        }

        svg {
            transform: translate(-20%, -0%);
        }

    }

    .toplink {
        aspect-ratio: 1;
        background: $light;
        box-shadow: 0 5px 20px rgba(0, 0, 0, .15);
        --link-color: #{$dark};
        display: flex;
        --link-hover-color: #{$secondary};
        padding: rem-calc(20);
        position: absolute;
        right: 0;
        transform: translateY(-50%);

        svg {
            height: auto;
            width: 20px;
        }
    }
}

