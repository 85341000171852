.top-bar {
    @extend %block-max-width;
    background: $medium;
    display: none;
    gap: $padding-lg;

    @include mappy-bp(lg) {
        display: flex;
    }

    a {
        font-family: $highlight-font-family;
        font-size: rem-calc(16);
        --link-hover-color: #{$dark};
        line-height: rem-calc(40);
    }
}
