@import 'header/top-bar';
@import 'header/socialmedia';

.page-header {
    position: relative;

    @include mappy-bp(lg) {
        position: sticky;
        top: 0;
        z-index: 100;
    }

    .main-header {
        @extend %block-max-width;
        background: $primary;
        display: flex;
        gap: $padding-lg;
        justify-content: space-between;

        @include mappy-bp(lg) {
            align-items: center;
            background: $light;
            padding-bottom: $padding;
            padding-top: $padding;
            justify-content: flex-start;

            &::after {
                content: '';
            }
        }

        @include mappy-bp(max-width) {
            justify-content: space-between;
        }

        .logo {
            display: block;
            padding: $padding 0;

            svg {
                height: 100px;
                width: auto;
            }

            @include mappy-bp(md) {
                height: auto;
            }

            @include mappy-bp(lg) {
                height: 500px;
                overflow: hidden;
                position: absolute;
                right: 0;
                top: 0;
                width: 300px;

                svg {
                    height: 130px;
                    position: relative;
                    transform: rotate(-90deg) translate(70px, -50px);
                    transform-origin: bottom right;
                    width: auto;
                    z-index: 1;
                }


                &::before {
                    background: $primary;
                    border-radius: 50%;
                    bottom: 30px;
                    box-shadow: 0 5px 25px rgba(0, 0, 0, .15);
                    content: '';
                    height: 600px;
                    left: 25px;
                    position: absolute;
                    width: 600px;
                    z-index: 1;
                }
            }
        }
    }
}
