.mobile-navigation {
    $icon-size: 60px;
    $transition-delay: .3s;
    $transition: .3s cubic-bezier(.5, 0, .25, 1);
    backdrop-filter: blur(5px);
    background: transparentize(#000, .2);
    bottom: 0;
    height: 100%;
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: fixed;
    top: 0;
    transition: $transition;
    transition-delay: $transition-delay;
    width: 100%;
    z-index: 10000;

    &.active {
        opacity: 1;
        pointer-events: all;
        transition-delay: 0s;

        .content {
            transform: translate(0);
            transition-delay: $transition-delay;
        }
    }

    .content {
        background: $light;
        bottom: 0;
        max-width: rem-calc(500);
        position: absolute;
        right: 0;
        top: 0;
        transform: translate(120%);
        transition: $transition;
        transition-delay: 0s;
        width: 100%;
    }

    .close {
        appearance: none;
        background: $primary;
        border: 0;
        color: $light;
        cursor: pointer;
        float: right;
        height: $icon-size;
        padding: $padding-sm;
        transition: $transition;
        width: $icon-size;


        @include mappy-bp(md) {
            float: none;
            left: 0;
            position: absolute;
            top: 0;
            transform: translate(-$icon-size);
        }

        &:hover {
            background: $secondary;
        }

        svg {
            display: block;
            height: auto;
            width: 100%;
        }
    }

    .nav {
        padding: $padding;

        .level-1 {
            a {
                color: $dark;
                font-family: $highlight-font-family;
                font-size: rem-calc(20);
            }
        }

        .level-2 {
            padding: $padding-sm 0 $padding;
            a {
                font-family: $base-font-family;
                font-size: rem-calc(16);
                line-height: rem-calc(20);
                opacity: .5;
                padding: $padding-xs 0;
            }
        }
        svg {
            display: none;
        }
    }
}
