//Variables
$img: '/typo3conf/ext/pixeltheme/Resources/Public/dist/images/';
$base-font-size: 1rem;

$highlight-font-weight: 400;

// Base
$rem-base-sm: 16px;
$body-font-size: 1rem;
$body-line-height: 1.5;
$body-font-weight: 400;
$bold-font-weight: 700;
$small-font-size: .8rem;

$base-font-family: 'Montserrat', sans-serif;
$highlight-font-family: 'Playfair Display', serif;

$headings: (
        h1: (size: rem-calc(40), lh: rem-calc(50)),
        h2: (size: rem-calc(30), lh: rem-calc(35)),
        h3: (size: rem-calc(22)),
        h4: (size: rem-calc(18)),
        h5: (size: rem-calc(16)),
        h6: (size: rem-calc(16)),
);

/**
 * Paddings & Sizes
 */
$paragraph-margin: $body-font-size * $body-line-height;
$padding: $paragraph-margin;
$padding-xs: $padding / 4;
$padding-sm: $padding / 2;
$padding-lg: $padding * 2;
$padding-xl: $padding * 3;
$padding-xxl: $padding * 4;

$grid-padding: $padding;

$text-max-width: rem-calc(800);
$content-max-width: 1400px;
$sidebar-width: 30%;

$logo-width: rem-calc(300);
$logo-height: rem-calc(61);
$logo-ratio: $logo-height / $logo-width;


//Colors

$base-font-color: #000;
$primary: #FA008A;
$secondary: #FFE82F;
$medium: #E4E3E2;
$light: #fff;
$dark: #000;
$error: #f00;

$selection-color: $light;
$selection-bg: $primary;

$border-color: #ddd;

$header-bg: $light;
$footer-bg: $dark;
$footer-text-color: $light;
$bottom-bar-bg: $secondary;
$bottom-bar-text-color: $dark;

$nav-toggler-background: $primary;
$nav-toggler-item-color: $secondary;
$nav-toggler-item-active: $primary;

$grid-bg-light: $light;
$grid-bg-dark: $dark;

$gallery-width: rem-calc(400);

$styles: (
        default: ('bg': $light, 'font': $base-font-color, 'highlight': $primary),
        medium: ('bg': $border-color, 'font': $base-font-color, 'highlight': $primary),
        primary: ('bg': $primary, 'font': $light, 'highlight': $light),
        secondary: ('bg': $secondary, 'font': $light, 'highlight': $light),
        dark: ('bg': $dark, 'font': $light, 'highlight': $light),
        bg: ('bg': $dark, 'font': $light, 'highlight': $light),
);

$default-transition-time: .3s cubic-bezier(.2, .9, .5, 1);


//Mappy Breakpoints
$breakpoints: (
        xs: 400px,
        sm: 600px,
        md: 768px,
        lg: 1024px,
        xl: 1280px,
        xxl: 1680px,
        xxxl: 1920px,
        max-width: $content-max-width,
);
