.artwork-slider {
    padding:0;
    overflow: hidden;

    .swiper {
        @extend %block-max-width;
        overflow: visible;
        display: none;

        &.swiper-initialized {
            display: block;
        }
    }
    .info {
        background: $primary;
        color: $light;
        text-align: center;
        padding: $padding;
        font-family: $highlight-font-family;
        font-size: rem-calc(30);

        @include mappy-bp(md) {
            padding: $padding-lg $padding-lg 0;
        }
    }

    .text {
        max-width: $text-max-width;
        margin: 0 auto $padding;
    }

    .link {
        text-align: center;
    }

    .button {
        @include btn;

        @include mappy-bp(md) {
            transform: translateY(50%);
        }
    }
}
