.hero {
    .hero-header {
        @extend %block-max-width;
        display: none;
        background: $primary;
        height: 180px;
        overflow: visible;

        @include mappy-bp(lg) {
            display: block;
        }

        svg {
            display: block;
            height: 250px;
            width: auto;
        }

        &:last-of-type {
            margin-bottom: rem-calc(70);
        }
    }

    .hero-content {
        @extend %block-max-width;
        padding-bottom: $padding;
        padding-top: $padding;

        @include mappy-bp(lg) {
            padding-bottom: 0;
            padding-top: 0;
            transform: translateY(-100px);
        }
    }
}
