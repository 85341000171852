@mixin btn {
    --link-color: #{$dark};
    --link-hover-color: #{$light};
    cursor: pointer;
    display: inline-block;
    padding: 0 $padding-lg;
    border-radius: rem-calc(30);
    appearance: none;
    outline: transparent;
    font-size: rem-calc(16);
    font-weight: $bold-font-weight;
    font-family: $base-font-family;
    color: var(--link-color);
    text-transform: uppercase;
    line-height: rem-calc(50);
    transition: $default-transition-time;
    background: $secondary;

    &:hover {
        background: $dark;
        color: $light;
    }
}
