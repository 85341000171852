.image-quote {
    display: grid;
    gap: $padding;
    grid-template-columns: 1fr 2fr;
    margin: 0 auto;
    max-width: $text-max-width;

    @include mappy-bp(lg) {
        align-items: end;
        gap: $padding-lg;
        grid-template-columns: 1fr 1fr;

        img {
            margin-bottom: $padding-lg;
        }
    }

    .quote {
        color: $primary;
        font-family: $highlight-font-family;
        font-size: 20px;
        font-weight: $bold-font-weight;
        letter-spacing: 2px;

        @include mappy-bp(md) {
        font-size: 30px;
        }
    }
}

.images-quote {
    max-width: $text-max-width;
    margin: 0 auto;

    .images {
        display: flex;
        gap: $padding;
        justify-content: center;
    }

    .quote {
        color: $light;
        font-family: $highlight-font-family;
        font-size: 30px;
        padding: $padding $padding-lg;
        text-align: center;
        background: $primary;
        max-width: rem-calc(400);
        margin: 0 auto;
        letter-spacing: 2px;
        transform: translateY(-$padding);
    }
}
