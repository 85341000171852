$highlight-font-size: rem-calc(35);

html, body {
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
}

p:last-child {
    margin-bottom: 0;
}

img {
    height: auto;
    max-width: 100%;
    width: 100%;
    display: block;
    transform: scale(1);
    overflow: hidden;

    &.blur {
        filter: saturate(20%) opacity(50%);
        opacity:0;
    }

    &.loaded {
        opacity:1;
        transition: opacity .25s, filter  .5s .25s;
    }
}

html {
    font-family: $base-font-family;
    font-size: $rem-base-sm;
    font-size: clamp($rem-base-sm, 1.25vw, $rem-base-sm * 1.2);
    font-weight: $body-font-weight;
}

body {
    color: var(--text-color);
    line-height: $body-line-height;
}

p {
    margin-bottom: $paragraph-margin;
}

@each $heading, $styles in $headings {
    #{$heading} {
        font-size: calc(#{map-get($styles, size)} / 1.2);
        margin: 0 0 $padding-sm;
        font-family: $highlight-font-family;
        font-weight: $highlight-font-weight;

        @include mappy-bp(md) {font-size: map-get($styles, size);}
        @if map-get($styles, color) {color: map-get($styles, color);}
        @if map-get($styles, lh) {line-height: map-get($styles, lh);}
    }
}

strong, b {
    font-weight: $bold-font-weight;
}

i, em {
    font-style: italic;
}

a {
    color: var(--link-color);
    text-decoration: none;
    transition: color $default-transition-time;

    &:hover { color: var(--link-hover-color); }
}

::-moz-selection {
    background: $selection-bg;
    color: $selection-color;
}

::selection {
    background: $selection-bg;
    color: $selection-color;
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.hidden {
    display: none;
}
