.meta {
   padding: $padding-lg 0 $padding;
    display: inline-block;

    .level-1 {
        align-items: center;
        display: flex;
        gap: $padding;
        justify-content: flex-end;

        @include mappy-bp(md) {
            justify-content: flex-start;
        }

        a {
            line-height: rem-calc(50);
            font-weight: $bold-font-weight;
        }
    }
}
