.social-media {
    --link-color: #{$secondary};
    --link-hover-color: #{$primary};
}

header .social-media {
    display: none;

    @include mappy-bp(lg) {
        display: flex;
        flex-direction: column;
        gap: $padding-sm;
    }

    svg {
        display: block;
        height: 30px;
        width: 30px;
    }
}
