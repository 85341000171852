%block-max-width {
  padding-left: $padding;
  padding-right: $padding;

  @include mappy-bp(xl) {
    padding-left: $padding-lg;
    padding-right: $padding-lg;
  }

  @include mappy-bp(max-width) {
    padding-left: calc((100vw - #{$content-max-width}) / 2 + #{$padding-lg});
    padding-right: calc((100vw - #{$content-max-width}) / 2 + #{$padding-lg});
  }
}

%block-max-width-0 {
  padding-left: 0;
  padding-right: 0;

  @include mappy-bp(md) {
    padding-left: $padding;
    padding-right: $padding;
  }

  @include mappy-bp(xl) {
    padding-left: $padding-lg;
    padding-right: $padding-lg;
  }

  @include mappy-bp(max-width) {
    padding-left: calc((100vw - #{$content-max-width}) / 2 + #{$padding-lg});
    padding-right: calc((100vw - #{$content-max-width}) / 2 + #{$padding-lg});
  }
}

@mixin fullsize($p) {
  padding-left: $p;
  padding-right: $p;

  @include mappy-bp(md) {
    padding-left: $padding;
    padding-right: $padding;
  }

  @include mappy-bp(xl) {
    padding-left: $padding-lg;
    padding-right: $padding-lg;
  }
}
