:root {
  --text-color: #{$base-font-color};
  --background-color: #{$light};
  --highlight-color: #{$primary};
  --border-radius: 5px;
  --border-color: #{$border-color};
  --link-color: #{$primary};
  --link-hover-color: #{$secondary};
  --header-height: rem-calc(100);
  --image-width: #{$gallery-width};

  --cc-bg: #{$light};
  --cc-text: #{$base-font-color};
  --cc-btn-primary-bg: #{$primary};
  --cc-btn-primary-text: var(--cc-bg);
  --cc-btn-primary-hover-bg: #{$secondary};
  --cc-btn-secondary-bg: #{lighten($primary, 10%)};
  --cc-btn-secondary-text: var(--cc-bg);
  --cc-btn-secondary-hover-bg: #{$secondary};
  --cc-toggle-bg-off: #919ea6;
  --cc-toggle-bg-on: #{$primary};
  --cc-toggle-bg-readonly: #d5dee2;
  --cc-toggle-knob-bg: #{$light};
  --cc-toggle-knob-icon-color: #{$light};
  --cc-block-text: var(--cc-text);
  --cc-cookie-category-block-bg: #f0f4f7;
  --cc-cookie-category-block-bg-hover: #e9eff4;
  --cc-section-border: #f1f3f5;
  --cc-cookie-table-border: #e9edf2;
  --cc-overlay-bg: rgba(4, 6, 8, .85);
  --cc-webkit-scrollbar-bg: #cfd5db;
  --cc-webkit-scrollbar-bg-hover: #9199a0;
}
