.headline {
    margin: 0 auto $padding-lg;
    max-width: $text-max-width;
    display: flex;
    flex-direction: column;
    align-items: center;

    h1, h2, h3, h4, h5, h6 {
        font-family: $base-font-family;
        font-size: rem-calc(25);
        font-weight: $bold-font-weight;
        line-height: rem-calc(25);
        margin: 0 0 $padding-sm 0;
        text-transform: uppercase;

        @include mappy-bp(md) {
            font-size: rem-calc(50);
            line-height: rem-calc(60);
        }

        & + {
            h2, h3, h4, h5, h6 {
                color: $primary;
                font-family: $highlight-font-family;
                font-size: rem-calc(20);
                font-weight: $body-font-weight;
                margin: 0;
                text-transform: none;

                strong, b {
                    font-style: normal;
                    font-weight: $bold-font-weight;
                }

                @include mappy-bp(md) {
                    font-size: rem-calc(30);
                }

                &.bg-black {
                    background: $dark;
                    color: $light;
                    padding: 0 $padding-sm;
                }
            }
        }
    }
}
