.navigation.main {
    display: none;

    @include mappy-bp(lg) {
        display: block;
        justify-self: center;
    }

    a {
        @include nolink;
        color: $dark;

    }

    .active > a {
        font-weight: $bold-font-weight;
        color: $primary;
    }

    ul.level-1 {
        align-items: center;
        display: flex;
        gap: $padding-lg;

        > li {
            position: relative;

            &:hover {
                > a {
                    color: $primary;
                }

                .level-2 {
                    opacity: 1;
                    pointer-events: all;
                }
            }

            > a {
                font-weight: $bold-font-weight;
                text-transform: uppercase;
                font-size: rem-calc(18);
                display: flex;
                gap: $padding-sm;
                align-items: center;

                svg {
                    width: 20px;
                    height: auto;
                }
            }
        }

        .level-2 {
            min-width: calc(100% + #{$padding-lg});
            background: $light;
            box-shadow: 0 10px 10px rgba(0,0,0,.2);
            left: -$padding;
            opacity: 0;
            padding: $padding;
            pointer-events: none;
            position: absolute;
            transition: .3s;
            white-space: nowrap;

            > li > a {
                line-height: rem-calc(40);

                &:hover {
                    color: $primary;
                }
            }
        }
    }
}
