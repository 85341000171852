.career {
    $n: '.c';
    position: relative;


    &::before,
    &::after {
        background-color: $light;
        background-position: top center;
        background-size: 300px 300px;
        content: '';
        display: block;
        left: rem-calc(15);
        position: absolute;
        top: calc(#{$padding} + 4px);
        transform: translate(-50%);
        width: 5px;
        z-index: 0;

        @include mappy-bp(md) {
            left: 50%;
        }
    }

    &::before {
        background-image: url('../../svg/carreerline.svg');
        bottom: $padding;
    }

    &::after {
        background-image: url('../../svg/carreerlineActive.svg')
    }

    @include mappy-bp(md) {
        &::after {
            height: calc(var(--career-height) - #{$padding});
            transition: $default-transition-time;
        }
    }

    #{$n}-item {
        align-items: center;
        display: grid;
        gap: $padding;
        grid-template-areas: 'dot year' 'nothing content';
        grid-template-columns: max-content 1fr;
        padding: $padding 0;
        position: relative;
        z-index: 1;

        @include mappy-bp(md) {
            grid-template-areas: 'content dot year';
            grid-template-columns: 1fr max-content 1fr;
        }

        &:nth-child(2n) {
            @include mappy-bp(md) {
                grid-template-areas: 'year dot content';
            }

            #{$n}-content {
                flex-direction: row-reverse;
            }

            #{$n}-year {
                @include mappy-bp(md) {
                    text-align: right;
                }
            }

            #{$n}-arrow {
                transform: unset;
            }
        }

        @include mappy-bp(md) {
            &:hover,
            &.active {
                &::before {
                    border-color: $primary;
                }

                #{$n}-year {
                    color: $primary;
                }

                #{$n}-arrow {
                    color: $primary;
                }

                #{$n}-dot {
                    color: $primary;
                }
            }
        }

    }

    #{$n}-dot {
        grid-area: dot;
        height: rem-calc(50);
        transition: $default-transition-time;
        width: rem-calc(50);
    }

    #{$n}-year {
        font-size: 1.75rem;
        font-weight: $bold-font-weight;
        grid-area: year;
        text-transform: uppercase;
        transition: $default-transition-time;

    }

    #{$n}-content {
        align-items: center;
        display: flex;
        flex-direction: row-reverse;
        gap: $padding-sm;
        grid-area: content;
        justify-content: flex-end;

        @include mappy-bp(md) {
            flex-direction: row;
        }
    }

    #{$n}-arrow {
        @include mappy-bp(md) {
            transform: scaleX(-1);
        }

        svg {
            display: block;
            height: rem-calc(24);
            transition: $default-transition-time;
            width: rem-calc(14);
        }
    }

    #{$n}-text {
        background: $medium;
        flex: 1;
        padding: $padding;

        a {
            --link-color: #{$dark};
            text-decoration: underline;
        }
    }
}
