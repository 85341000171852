.images-quote-link {
    .images {
        display: flex;
        justify-content: center;

        @include mappy-bp(md) {
            gap: $padding;
        }

        img {
            display: none;
            &:first-child { display: block;}

            @include mappy-bp(md) {
                display: block;
            }
        }
    }

    .quote-container {
        background: $primary;
        margin: 0 auto;
        max-width: rem-calc(600);
        padding: $padding-lg $padding-lg 0;

        @include mappy-bp(md) {
            transform: translateY(-$padding-lg);
        }
    }

    .quote {
        color: $light;
        font-family: $highlight-font-family;
        font-size: rem-calc(30);
        margin: 0 auto $padding;
        text-align: center;
    }

    .link {
        text-align: center;
        transform: translateY(50%);
    }

    .button {
        @include btn;
    }
}
