.form {
    .form-fields {
        display: grid;
        gap: $padding-sm;
    }

    .field {
        &.text,
        &.textarea {
            background: $light;
            display: flex;
            flex-direction: column;
            position: relative;

            input,
            textarea {
                border: none;
                color: $base-font-color;
                font-family: $base-font-family;
                font-size: $base-font-size;
                line-height: $padding;
                outline: transparent;
                padding: $padding-lg $padding $padding;
            }

            label {
                left: $padding;
                pointer-events: none;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                transition: .3s;
            }

            &:has(:focus, .filled) {
                label {
                    font-size: .7rem;
                    opacity: .5;
                    top: $padding;
                    transform: translateY(0);
                }
            }

            &.error {
                label {
                    color: $error;
                }
            }
        }

        &.textarea {
            label {
                top: $padding-lg;
            }
        }

        &.toggle {
            label {
                @include mappy-bp(md) {
                    align-items: center;
                    display: flex;

                    a {
                        display: inline-block;
                        margin-left: rem-calc(5);
                    }
                }
            }

            input {
                accent-color: $primary;
                height: rem-calc(24);
                margin-right: $padding-sm;
                width: rem-calc(24);
            }
        }
    }

    .button-bar {
        padding-top: $padding;
        text-align: center;
    }

    .btn {
        appearance: none;
        border: 0;
        @include btn;
    }
}

.kontaktformular {
    .form-fields {
        @include mappy-bp(md) {
            display: grid;
            grid-template-columns: 1fr 1fr;

            .name {
                &-nachricht,
                &-datenschutz {grid-column: span 2;}
            }

        }
    }
}
