.textpic {
    display: flex;
    flex-direction: column;
    gap: $padding;

    @include mappy-bp(md) {
        flex-direction: row;
        justify-content: space-between;

        &.image-right {
            flex-direction: row-reverse;
        }

        &.image-top {
            flex-direction: column;
        }

        &.image-bottom {
            flex-direction: column-reverse;
        }

        &.align {
            &-top { align-items: flex-start;}
            &-center { align-items: center;}
            &-bottom { align-items: flex-end;}
        }

        .image {
            width: var(--image-width);
        }

        .text {
            flex:1;
        }
    }
}
