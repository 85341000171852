.artworks {
    gap: $padding;
    @include mappy-bp(md) {
        display: grid;
        grid-auto-flow: dense;
        grid-template-columns: 1fr 1fr 1fr;
    }

    .artwork {
        overflow: hidden;
        position: relative;

        &:hover {
            .hover-content {
                opacity: 1;
                transform: scale(1);
            }

            img {
                transform: scale(1.2);
            }
        }

        &.item-5 {
            @include mappy-bp(md) {
                grid-column: 2 / span 2;
                grid-row: 2 / span 2;
            }
        }

        img {
            aspect-ratio: 1;
            transition: 1s;
        }

        .hover-content {
            @include mappy-bp(md) {
                align-items: center;
                background: transparentize($primary, .5);
                color: $light;
                display: grid;
                font-family: $highlight-font-family;
                font-size: rem-calc(30);
                inset: 0;
                justify-content: center;
                opacity: 0;
                padding: $padding;
                position: absolute;
                transform: scale(1.5);
                transition: .3s;
            }
        }
    }
}
