.content-element {
    @extend %block-max-width;
    background: var(--background-color);
    margin-top: var(--mt);
    margin-bottom: var(--mb);

    &.padding {
        &-xs {@include padding($padding-xs); }
        &-s {@include padding($padding-sm); }
        &-default {@include padding($padding); }
        &-l {@include padding($padding-lg); }
        &-xl {@include padding($padding-xl); }
    }

    &.color {
        &-light { color: $light;}
        &-dark { color: $dark;}
        &-default {color: $base-font-color;}
    }
}

@import 'textpic';
@import 'simple-content';
@import 'headline';
@import '2-column-layout';
@import 'page-teaser';
@import 'homepage-hero';
@import 'images-quote-link';
@import 'hero-image-quote';
@import 'quote-block';
@import 'content-slider';
@import 'career';
@import 'artworks';
@import 'artwork-slider';
@import 'feedback';
