.quote-block {
    padding-top: rem-calc(200);
    position: relative;
    margin: $padding-lg 0;

    .short-quote {
        color: $primary;
        font-family: $highlight-font-family;
        font-size: rem-calc(30);
        letter-spacing: 2px;
        margin-bottom: $padding;
        text-align: center;
        z-index: 1;

        @include mappy-bp(md) {
            left: 50%;
            margin: 0;
            position: absolute;
            text-align: left;
            text-orientation: mixed;
            top: $padding-lg;
            transform: translate(-50%) rotate(180deg);
            writing-mode: vertical-lr;
        }
    }

    .quote-container {
        background: $primary;
        color: $light;
        font-family: $highlight-font-family;
        font-size: rem-calc(30);
        text-align: center;
    }

    .quote {
        margin: 0 auto;
        max-width: $text-max-width;
        padding: $padding-lg 0 $padding;
    }

    .button {
        @include btn;
        position: relative;
        transform: translateY(50%);
        z-index: 100;
    }
}
