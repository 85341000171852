.content-slider {
  --icon-color: #{$primary};
  --swiper-navigation-color: #{$dark};
  overflow: hidden;
  position: relative;

  .slider {
    overflow: visible;
  }

  .title {
    margin-bottom: $padding-lg;
    position: relative;

    h3 {
      font-family: $base-font-family;
      font-weight: $bold-font-weight;
      position: relative;
      text-align: left;
      text-orientation: mixed;
      text-transform: uppercase;
      transform: rotate(180deg);
      writing-mode: vertical-lr;
    }

    svg {
      bottom: 0;
      left: 0;
      position: absolute;
      transform: translate(-40%, 20%);
      width: min(80%, 250px);
      z-index: 0;
    }

    &::before {
      aspect-ratio: 1;
      background: var(--icon-color);
      border-radius: 50%;
      bottom: 0;
      content: '';
      left: 0;
      position: absolute;
      transform: translate(-50%, 50%);
      width: min(100%, 300px);
      z-index: 0;
    }
  }

  .slide-content {
    padding-left: 25%;
    padding-right: $padding;
  }

  .text {
    line-height: 2;
    position: relative;
  }

  .link {
    padding-top: $padding-lg;
    text-align: center;
  }

  .button {
    @include btn;
  }

  .slide {
    padding-top: rem-calc(80);
  }

  .slide:nth-child(even) {
    --icon-color: #{$secondary};

    .title {
      svg {
        transform: translate(-30%, 20%);
      }
    }
  }

  .swiper-button {
    &-next {
      @include mappy-bp(md) {
        right: -$padding;
      }
    }

    &-prev {
      @include mappy-bp(md) {
        left: -$padding;
      }
    }

    &-disabled {
      display: none;
    }
  }
}

.custom-pagination {
  display: flex;
  gap: $padding-xs;
  justify-content: center;
  padding-top: $padding-lg;

  .swiper-pagination-bullet {
    aspect-ratio: 1;
    background: $border-color;
    height: rem-calc(14);
    margin: 0 !important;
    opacity: 1;
    transition: $default-transition-time;
    width: rem-calc(14);

    &.swiper-pagination-bullet-active {
      background: $primary;
    }

    &:hover {
      background: $secondary;
    }
  }
}
