.simple-content {
    .sc-item:not(:last-child) {
        margin-bottom: $padding-lg;
    }

    p {
        line-height: 2;

        &.text-center {
            margin-left: auto;
            margin-right: auto;
            max-width: $text-max-width;
        }
    }
}

.simple-button {

    &.align-center {
        text-align: center;
    }

    &.align-right {
        text-align: right;
    }

    .button {
        @include btn;
    }
}
