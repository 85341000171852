/* playfair-display-regular - latin */
@font-face {
    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 400;
    src: url('../webfonts/playfair-display-v30-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../webfonts/playfair-display-v30-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../webfonts/playfair-display-v30-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('../webfonts/playfair-display-v30-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('../webfonts/playfair-display-v30-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../webfonts/playfair-display-v30-latin-regular.svg#PlayfairDisplay') format('svg'); /* Legacy iOS */
}
/* playfair-display-700 - latin */
@font-face {
    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 700;
    src: url('../webfonts/playfair-display-v30-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../webfonts/playfair-display-v30-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../webfonts/playfair-display-v30-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('../webfonts/playfair-display-v30-latin-700.woff') format('woff'), /* Modern Browsers */
    url('../webfonts/playfair-display-v30-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../webfonts/playfair-display-v30-latin-700.svg#PlayfairDisplay') format('svg'); /* Legacy iOS */
}
/* playfair-display-italic - latin */
@font-face {
    font-family: 'Playfair Display';
    font-style: italic;
    font-weight: 400;
    src: url('../webfonts/playfair-display-v30-latin-italic.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../webfonts/playfair-display-v30-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../webfonts/playfair-display-v30-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('../webfonts/playfair-display-v30-latin-italic.woff') format('woff'), /* Modern Browsers */
    url('../webfonts/playfair-display-v30-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../webfonts/playfair-display-v30-latin-italic.svg#PlayfairDisplay') format('svg'); /* Legacy iOS */
}
/* playfair-display-700italic - latin */
@font-face {
    font-family: 'Playfair Display';
    font-style: italic;
    font-weight: 700;
    src: url('../webfonts/playfair-display-v30-latin-700italic.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../webfonts/playfair-display-v30-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../webfonts/playfair-display-v30-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('../webfonts/playfair-display-v30-latin-700italic.woff') format('woff'), /* Modern Browsers */
    url('../webfonts/playfair-display-v30-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../webfonts/playfair-display-v30-latin-700italic.svg#PlayfairDisplay') format('svg'); /* Legacy iOS */
}

/* montserrat-300 - latin */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    src: url('../webfonts/montserrat-v25-latin-300.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../webfonts/montserrat-v25-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../webfonts/montserrat-v25-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('../webfonts/montserrat-v25-latin-300.woff') format('woff'), /* Modern Browsers */
    url('../webfonts/montserrat-v25-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../webfonts/montserrat-v25-latin-300.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-regular - latin */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: url('../webfonts/montserrat-v25-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../webfonts/montserrat-v25-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../webfonts/montserrat-v25-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('../webfonts/montserrat-v25-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('../webfonts/montserrat-v25-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../webfonts/montserrat-v25-latin-regular.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-700 - latin */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    src: url('../webfonts/montserrat-v25-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../webfonts/montserrat-v25-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../webfonts/montserrat-v25-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('../webfonts/montserrat-v25-latin-700.woff') format('woff'), /* Modern Browsers */
    url('../webfonts/montserrat-v25-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../webfonts/montserrat-v25-latin-700.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-300italic - latin */
@font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 300;
    src: url('../webfonts/montserrat-v25-latin-300italic.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../webfonts/montserrat-v25-latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../webfonts/montserrat-v25-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('../webfonts/montserrat-v25-latin-300italic.woff') format('woff'), /* Modern Browsers */
    url('../webfonts/montserrat-v25-latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../webfonts/montserrat-v25-latin-300italic.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-italic - latin */
@font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 400;
    src: url('../webfonts/montserrat-v25-latin-italic.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../webfonts/montserrat-v25-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../webfonts/montserrat-v25-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('../webfonts/montserrat-v25-latin-italic.woff') format('woff'), /* Modern Browsers */
    url('../webfonts/montserrat-v25-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../webfonts/montserrat-v25-latin-italic.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-700italic - latin */
@font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 700;
    src: url('../webfonts/montserrat-v25-latin-700italic.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../webfonts/montserrat-v25-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../webfonts/montserrat-v25-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('../webfonts/montserrat-v25-latin-700italic.woff') format('woff'), /* Modern Browsers */
    url('../webfonts/montserrat-v25-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../webfonts/montserrat-v25-latin-700italic.svg#Montserrat') format('svg'); /* Legacy iOS */
}
